import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import Slider from 'react-slick';

import RichText, { domToReact } from '@/components/RichText/RichText';
import useFormatMessage from '@/hooks/useFormatMessage';
import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';

import {
  Description,
  Grid,
  Header,
  Row,
  SliderWrapper,
  Tile,
  Wrapper,
} from './ProductCatalogue.style';

/**
 * ProductCatalog.
 */

const ProductCatalog = ({
  products,
  headline,
  description,
  imageComponentType,
}) => {
  const sliderRef = useRef(null);
  const mobileMediaQuery = useMobileMediaQuery();
  const largeMediaQuery = useMediaQuery(theme => theme.breakpoints.up('lg'));

  const formatMessage = useFormatMessage();

  const onPrev = spaces => {
    const sliderState = sliderRef.current.innerSlider.state;
    const { currentSlide, slideCount } = sliderState;

    let slidesToJump = 0;
    let spacesToJump = 0;
    const startingIndex =
      currentSlide === 0 ? slideCount - 1 : currentSlide - 1;
    for (
      let index = startingIndex;
      index > startingIndex - spaces;
      index -= 1
    ) {
      const arrayIndex = index < 0 ? slideCount + index : index;
      spacesToJump += products[arrayIndex].space;

      if (spacesToJump > spaces) {
        break;
      }

      slidesToJump += 1;
    }

    sliderRef.current.slickGoTo(currentSlide - slidesToJump);
  };

  const onNext = spaces => {
    const sliderState = sliderRef.current.innerSlider.state;
    const { currentSlide, slideCount } = sliderState;

    let slidesToJump = 0;
    let spacesToJump = 0;
    for (let index = currentSlide; index < currentSlide + spaces; index += 1) {
      const arrayIndex = index >= slideCount ? index % slideCount : index;
      spacesToJump += products[arrayIndex].space;

      if (spacesToJump > spaces) {
        break;
      }

      slidesToJump += 1;
    }

    sliderRef.current.slickGoTo(currentSlide + slidesToJump);
  };

  const sliderOptions = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    rows: 1,
    variableWidth: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          rows: 1,
        },
      },
    ],
    prevArrow: (
      <button
        className="slick-arrow slick-prev"
        aria-label={formatMessage('arrow_left')}
        onClick={() => onPrev(mobileMediaQuery ? 2 : 4)}>
        <ChevronLeftIcon />
      </button>
    ),
    nextArrow: (
      <button
        className="slick-arrow slick-next"
        aria-label={formatMessage('arrow_right')}
        onClick={() => onNext(mobileMediaQuery ? 2 : 4)}>
        <ChevronRightIcon />
      </button>
    ),
  };

  const renderTile = product => (
    <Tile
      key={product.id}
      image={product.image}
      url={product.link}
      space={product.space}
      name={product.name}
      imageComponentType={imageComponentType}
    />
  );

  const renderTiles = products => products.map(renderTile);

  const renderSlider = () => (
    <SliderWrapper>
      {sliderOptions.prevArrow}
      <Slider {...sliderOptions} ref={sliderRef}>
        {renderTiles(products)}
      </Slider>
      {sliderOptions.nextArrow}
    </SliderWrapper>
  );

  const renderRow = () => (
    <Grid>
      <Row key={`row-${products.length}`}>{renderTiles(products)}</Row>
    </Grid>
  );

  const renderConditionally = () =>
    largeMediaQuery ? renderRow() : renderSlider();

  const usedTotalSpace = products.reduce((pv, cv) => pv + cv.space, 0);

  const catalog = usedTotalSpace > 12 ? renderSlider() : renderConditionally();

  const richTextReplacer = (element, wrappedReplacer) => {
    if (element.name === 'h2' && element.children.length) {
      return (
        <Header align="center" variant="h2">
          {domToReact(element.children, { replace: wrappedReplacer })}
        </Header>
      );
    }

    return null;
  };

  return (
    <Wrapper>
      <RichText data={headline} replace={richTextReplacer} />
      <Description align="center" variant="body1">
        {description}
      </Description>
      {catalog}
    </Wrapper>
  );
};

ProductCatalog.propTypes = {
  /** Headline */
  headline: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  /** Description */
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  /** Product list to be displayed */
  products: PropTypes.array.isRequired,
  imageComponentType: PropTypes.oneOf(['strapi', 'next']),
};

ProductCatalog.defaultProps = {
  headline: '',
  description: '',
  imageComponentType: 'strapi',
};

export default ProductCatalog;
