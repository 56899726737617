import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import ProductTile from '@/components/ui/ProductTile/ProductTile';

export const Wrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(8.5),

  '.slick-arrow': {
    color: theme.palette.common.white,
    width: 48,
    height: 76,
    background: 'rgba(216, 216, 216, 0.7)',
    border: 0,
    borderRadius: 4,
    cursor: 'pointer',
    position: 'absolute',
    zIndex: 1,
    bottom: '50%',
  },

  '.slick-slide > *': {
    height: '100%',
  },

  '.slick-arrow.slick-prev': {
    left: 0,
    transform: 'translateX(calc(-50% + 6px)) translateY(38px)',
  },

  '.slick-arrow.slick-next': {
    right: 0,
    transform: 'translateX(calc(50% - 6px)) translateY(38px)',
  },

  [theme.breakpoints.up('md')]: {
    '.slick-slide': {
      width: 'auto !important',
    },
  },

  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4),

    '.slick-slider': {
      height: 266,
    },

    '.slick-arrow': {
      bottom: 'calc(50% - 20px)',
    },

    '.slick-arrow.slick-prev': {
      transform: 'translateX(calc(-50% + 16px)) translateY(38px)',
    },

    '.slick-arrow.slick-next': {
      transform: 'translateX(calc(50% - 16px)) translateY(38px)',
    },
  },
  [theme.breakpoints.down(480)]: {
    '.slick-slider': {
      height: 306,
      marginBottom: theme.spacing(5.5),
    },
  },
  [theme.breakpoints.down(414)]: {
    '.slick-slider': {
      height: 251,
    },
  },
  [theme.breakpoints.down(375)]: {
    '.slick-slider': {
      height: 222,
    },
  },
  [theme.breakpoints.down(360)]: {
    '.slick-slider': {
      height: 211,
    },
  },
}));

export const Header = styled(Typography)(({ theme }) => ({
  marginTop: 0,
  marginBottom: theme.spacing(1.5),

  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(2.5),
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'repeat(3, auto)',
  gridGap: '12px 0',
  justifyItems: 'center',
  marginTop: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    marginLeft: -6,
    display: 'grid',
  },
}));

export const Row = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, auto)',
}));

export const Tile = styled(ProductTile)(() => ({
  margin: '0 6px 0 6px',
}));

export const SliderWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(4),
}));
