import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import NextImage from 'next/image';

export const Wrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'isDouble',
})(({ theme, isDouble }) => [
  {
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    width: isDouble ? 558 : 273,

    [theme.breakpoints.down('md')]: {
      width: isDouble ? 342 : 165,
    },
    [theme.breakpoints.down(480)]: {
      width: isDouble ? 390 : 190,
    },
    [theme.breakpoints.down(414)]: {
      width: isDouble ? 324 : 156,
    },
    [theme.breakpoints.down(375)]: {
      width: isDouble ? 288 : 138,
    },
    [theme.breakpoints.down(360)]: {
      width: isDouble ? 300 : 131,
    },
  },
  isDouble
    ? {
        '&:nth-of-type(3n + 1)': {
          gridColumnStart: 1,
          gridColumnEnd: 2,
        },

        /* the second double in a row */
        '&:nth-of-type(3n + 2)': {
          gridColumnStart: 2,
          gridColumnEnd: 4,
        },

        /* the third double in a row */
        '&:nth-of-type(3n + 3)': {
          gridColumnStart: 3,
          gridColumnEnd: 5,
        },
      }
    : null,
]);

export const ImageWrapper = styled('div')(({ theme }) => ({
  height: 440,
  width: '100%',
  transition: 'all 0.2s ease',
  display: 'block',
  position: 'relative',

  [theme.breakpoints.up('md')]: {
    [`${Wrapper}:hover &`]: {
      opacity: 0.6,
    },
  },
  [theme.breakpoints.down('md')]: {
    height: 266,
  },
  [theme.breakpoints.down(480)]: {
    height: 306,
  },
  [theme.breakpoints.down(414)]: {
    height: 251,
  },
  [theme.breakpoints.down(375)]: {
    height: 222,
  },
  [theme.breakpoints.down(360)]: {
    height: 211,
  },
}));

export const Image = styled(NextImage)(() => ({
  maxWidth: '100%',
  height: 'auto',
  objectFit: 'contain',
}));

export const LabelWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: 85,
  backgroundColor: theme.palette.secondary.dark,
  opacity: '0.9',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  transition: 'all 0.2s ease',
  bottom: 0,

  [theme.breakpoints.up('md')]: {
    [`${Wrapper}:hover &`]: {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  [theme.breakpoints.down('md')]: {
    height: 48,
  },
}));

export const Label = styled(Typography)(() => ({
  maxWidth: '90%',
}));
